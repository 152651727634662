
.card-img {
    margin-left: 10%;
    margin-right: 10%;
    width: 180px;
    height: 200px;
}
.card-label {
    margin-top: -50px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(110, 103, 103);
    width: 300px;
    height: 260px;
    border-radius: 10px;
}

.label-text {
    color: white;
    font-family: Questrial;
    font-size: 30px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 15px;
    margin-top: 60px;
}

.text {
    color: rgb(251, 251, 251);
    font-family: Questrial;
    font-size: 15px;
    font-weight: 200;
    line-height: 21px;
    letter-spacing: 0em;
    margin-left: 20px;
    text-align: left;
    margin-top: -22px;
}