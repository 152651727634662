.Home {
    text-align: center;
    background: radial-gradient(103.05% 50% at 0% 100%, rgba(0, 163, 255, 0.14) 0%, rgba(0, 163, 255, 0.06) 53.12%, rgba(0, 163, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
radial-gradient(100% 50.01% at 0% 0%, rgba(0, 163, 255, 0.14) 0%, rgba(0, 163, 255, 0.06) 53.13%, rgba(0, 163, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
linear-gradient(0deg, #1B1330, #1B1330);
    width: 100%;
    /* height: 20000px; */
    margin-top: -50px;
    overflow: hidden;
}

.Image1-div {
    background-color: radial-gradient(103.05% 50% at 0% 100%, rgba(0, 163, 255, 0.14) 0%, rgba(0, 163, 255, 0.06) 53.12%, rgba(0, 163, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(100% 50.01% at 0% 0%, rgba(0, 163, 255, 0.14) 0%, rgba(0, 163, 255, 0.06) 53.13%, rgba(0, 163, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(0deg, #1B1330, #1B1330);
}

.Image1-item {
    width: 50%;
    height: 55%;
    margin-top: -50%;
    mix-blend-mode: screen;
}

.Image2-item {
    width: 80%;
    height: 55%;
    margin-top: 8%;
    mix-blend-mode: screen;
}

.text1 {
    color: #FFFFFF;
    font-family: Michroma;
    font-size: 300%;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -38%;
}

.text2 {
    color: #d4cdcd;
    letter-spacing: 0em;
    text-align: center;
    /* margin-top: -5%; */
}

.text3 {
    color: #FFFFFF;
    font-family: Michroma;
    font-size: 40px;
    font-weight: 400;
    line-height: 57px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 22%;
}

.text4 {
    color: #FFFFFF;
    font-family: Questrial;
    font-size: 120%;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -2%;
}

.text5 {
    color: #FFFFFF;
    font-family: Michroma;
    font-size: 100%;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.grid {
    margin-top: 15px;
}

.grid-menu {
    margin-top: 15px;
    text-align: right;
}

.group-image {
    width: 8pc;
}

.grid-div {
    border-width: 2px;
    border-color: #d4cdcd;
    border-style: solid;
    height: 150px;
    width: 100px;
    text-align: left;
}

.box-div {
    height: 20%;
    width: 100%;
    background:linear-gradient(89.6deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.06) 100%);
    display: block;
}

.box-div-text {
    color: rgb(240, 234, 234);
    font-family: Michroma;
    font-size: 40px;
    font-weight: 400;
    line-height: 57px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 20px;
}

.footer-div {
    background: linear-gradient(89.6deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.06) 100%);
    width: 100%;
    height: 218px;
    top: 5520px;
    left: 1px;
    margin-top: 100px;
    
}

.footer-image {
    margin-top: 30px;
    width: 15pc;
}

.footer-text {
    color: #FFFFFF;
    font-family: Questrial;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
}